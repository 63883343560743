import React from "react";
import styled from "styled-components";
import {
  IoSearch,
  IoPerson,
  IoCalculator,
  IoHappy,
  IoAnalytics,
  IoSettings,
  IoShieldCheckmark,
  IoFlash,
  IoList,
  IoCheckmark,
  IoCalendar,
  IoTimer,
  IoHourglass,
  IoStorefront,
  IoGlobe,
} from "react-icons/io5";
import { Link } from "gatsby";
import { useModal } from "../components/useModal";

import Image from "../components/image";
import {
  Feature,
  CustomerQuote,
  Modal,
  ImageGraphic,
  LogoScroller,
  CallToAction,
} from "../components/site";
import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const CustomerName = styled.h4`
  color: #fff;
  position: absolute;
  bottom: 60px;
  left: 60px;
`;

const Hospitality = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  const industry_benefits = [
    "Track hourly work with mobile timesheets, GPS or photo time clock",
    "Approve timesheets before paying employees",
    "Automatically determine leave and public holiday entitlements",
    "Easy app-based leave requests and approvals",
    "Automate payday filing with direct integration to Inland Revenue",
  ];

  return (
    <Layout>
      <Seo
        title="Payroll Software for Hospitality Businesses"
        description="Stress-free timesheets, rosters, attendance & payroll for hospitality businesses in New Zealand. Pay your staff quickly and accurately with the photo time clock. Get started today."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={70} noPaddingBottom>
          <Row stackGap={80} alignCenter>
            <Box size={50} stackGap={50} centerOnMobile>
              <Box stackGap={20}>
                <h1 className="-lg -primary badge">Payroll for Hospitality</h1>
                <h2 className="h1">
                  Manage your labour costs{" "}
                  <span className="-fontPrimary">
                    with intelligent payroll built for variable hour employees
                  </span>
                </h2>
                <Box stackGap={15}>
                  {industry_benefits.map((item, i) => {
                    return (
                      <Row
                        alignCenter
                        noBreak
                        stackGap={10}
                        justify="flex-start"
                        key={i}
                        css={{ fontSize: "1.2rem", textAlign: "left" }}
                        centerOnMobile
                      >
                        <IoCheckmark
                          css={{
                            fontSize: "1.8rem",
                            color: "#5eb22e",
                            flex: "0 0 auto",
                          }}
                        />
                        <span>{item}</span>
                      </Row>
                    );
                  })}
                </Box>
              </Box>
              <Box stackGap={15}>
                <FlexButtons>
                  <Button className="primary -lg gtm-cta" to="/signup">
                    Try 14 Days Free
                  </Button>
                  <Button
                    className="grey -lg gtm-cta"
                    onClick={toggleModal}
                    atag
                  >
                    Book a Sales Call
                  </Button>
                </FlexButtons>
                <p css={{ color: "#999" }}>
                  Get started in minutes, no credit card required.
                </p>
              </Box>
            </Box>
            <Box size={50} css={{ padding: "40px" }}>
              <ImageGraphic variant="1" />
              <Image
                filename="Hospo_Hero.png"
                alt="Payroll for Hospitality"
                centerImage
                addShadow
                rounded
              />
              <CustomerName>
                <b className="-handWritten">Panhead Custom Ales,</b>
                <br /> <span css={{ fontSize: "1rem" }}>PayHero Customer</span>
              </CustomerName>
            </Box>
          </Row>
          <LogoScroller>
            <Image
              filename="FlexiTimeHospitalityCustomers.png"
              alt="PayHero Hospitality Payroll Customers"
              centerImage
            />
          </LogoScroller>
          <CustomerQuote
            quote="The nightmare of public holiday calculations is not my problem anymore."
            name="Pippa Scott"
            company="The Gelato Lab"
            pic="GelatoLab_Circle.png"
            center
            large
          />
          <hr />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper stackGap={150}>
          <Row stackGap={100} alignCenter mobileReverse>
            <Box size={50}>
              <ImageGraphic variant="2" small />
              <Image
                alt="Clock In & Out | Photo Time Clock | PayHero"
                filename="PayHeroShift_Home.png"
                maxWidth={550}
                addShadow
                centerImage
                rounded
              />
            </Box>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Clean employee timesheets
                </h2>
                <h4 className="h2">
                  Record accurate time and pay employees right
                </h4>
              </Box>
              <Feature
                icon={<IoTimer key={0} />}
                title="Perfect for tracking hourly work"
                description="Track hourly work with ease and automatically calculate pay and entitlements for part time, casual, contract or shift workers. Let staff record hours against different work and cost centres with online & mobile timesheets, or capture exact start and finish times with photo clock in/out or GPS location tracking."
              />
              <Feature
                icon={<IoHourglass key={0} />}
                title="Seamlessly manage paid and unpaid breaks"
                description={[
                  "Employees can record breaks on the PayHero ",
                  <Link to="/timeclock">time clock</Link>,
                  " app, and PayHero will automatically figure out if the break should be a paid 10-minute rest break or an unpaid 30-minute meal break. Minimise the burden on managers and allow employees to document their breaks themselves.",
                ]}
              />
              <Feature
                icon={<IoCalendar key={0} />}
                title="Sync with Droppah rostering"
                description={[
                  "Take your ",
                  <Link to="/rostering">rostering</Link>,
                  " to the next level with Droppah. Use AI auto-scheduling or create a roster that takes skills, availability, shift preferences, wage costs, and other requirements into account. Achieve automated and highly optimised rosters in no time.",
                ]}
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="Everyone is excited about the move to PayHero, with overwhelmingly positive feedback across the brewery. You’ve got a fantastic product there."
            name="Ben Bakker"
            company="Panhead"
            pic="Panhead_Circle.png"
            large
            center
          />
          <Row stackGap={100} alignCenter mobileReverse>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Stress-free payroll
                </h2>
                <h4 className="h2">
                  Payroll software built to make the complex simple
                </h4>
              </Box>
              <Feature
                icon={<IoStorefront key={0} />}
                title="Easily manage multiple stores"
                description="Manage time, leave and payroll for different locations with PayHero Teams. Assign staff members to one or more stores and give local managers access to manage leave approvals and timesheets at their site. Track your wage costs with Xero tracking category integration."
              />
              <Feature
                icon={<IoPerson key={0} />}
                title="Employee self-service"
                description="Save time and hassle by letting your staff view leave balances and request leave from the employee app. They can access their old payslips too, so you don’t need to dig them out."
              />
              <Feature
                icon={<IoCalculator key={0} />}
                title="Calculate public holidays automatically"
                description="No more painstaking public holiday calculations or costly errors! Save on paperwork and manual processing by using timesheet history to automatically calculate holiday entitlements for variable hour employees."
              />
            </Box>
            <Box size={50}>
              <ImageGraphic variant="2" />
              <Image
                alt="Sync timesheets from mobile directly to payroll"
                filename="PayHero_Review_Hospo.png"
                addShadow
                centerImage
                rounded
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="Recently an accounting firm was pitching me for business. They told me they could do my payroll in just two hours a week. I told them it only takes me 10 minutes."
            name="Rochelle Harrison"
            company="Wellington Chocolate Factory"
            pic="Rochelle_Circle.png"
            large
            center
          />
          <Row stackGap={100} alignCenter>
            <Box size={50}>
              <Image
                filename="PayrollForHospitality_PayHero.jpg"
                alt="Payroll software compliance for Hospitality"
                centerImage
                rounded
              />
            </Box>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Simplify compliance
                </h2>
                <h4 className="h2">
                  Focus on your customers & staff and let us take care of the
                  tricky stuff
                </h4>
              </Box>
              <Feature
                icon={<IoFlash key={0} />}
                title="Automated payday filing"
                description={[
                  <Link to="/payday-filing">Payday filing</Link>,
                  <span>
                    {" "}
                    is a piece of cake in PayHero – just connect your account to
                    myIR and we’ll automatically send your payroll information
                    through after every pay.
                  </span>,
                ]}
              />
              <Feature
                icon={<IoShieldCheckmark key={0} />}
                title="Stay onside with the Holidays Act"
                description="Hospo is a hotbed of potentially tricky payroll scenarios with part time and variable hour staff and regularly changing work patterns. PayHero follows the latest MBIE Holidays Act guidance to ensure your employees maintain the correct holiday entitlements, even when things change."
              />
              <Feature
                icon={<IoGlobe key={0} />}
                title="Integrate with Xero"
                description="Automatically send payroll data through to Xero. Keep it simple or assign wage costs to different account codes and tracking categories for a more detailed breakdown."
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="The only software on the market currently able to accurately calculate the leave entitlements for employees with irregular working patterns, without manual intervention, is PayHero."
            name="Irene Bennetts"
            company="Admin Army"
            pic="Irene_Circle.png"
            large
            center
          />
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <h2 className="-textCenter">
            All the tools you need to run your hospo business better
          </h2>
          <Box stackGap={40}>
            <Row stackGap={40} className="-textCenter">
              <Box size={33}>
                <h3>
                  <IoCalculator />
                  <br />
                  It all adds up
                </h3>
                <p>
                  No more rounding to the nearest 15 minutes or having a drink
                  while still on the clock. With the time clock, on average our
                  hospo customers save 8 minutes per employee per shift – or
                  over $787 a year for each full time employee earning minimum
                  wage.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoAnalytics />
                  <br />
                  Insight into finances and staffing
                </h3>
                <p>
                  Track your wage costs across departments, ensuring you never
                  go over budget. Compare staff costs with your revenue to
                  ensure you have the right number of staff rostered on at busy
                  and quiet periods.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoSettings />
                  <br />
                  Easy set up
                </h3>
                <p>
                  Grab an iPad or Android tablet, sign in to the photo time
                  clock app and you’re ready to go. No huge setup costs or
                  annual maintenance fees like with fingerprint scanners or
                  card-based systems.
                </p>
              </Box>
            </Row>
            <Row stackGap={40} className="-textCenter">
              <Box size={33}>
                <h3>
                  <IoList />
                  <br />
                  Works the way your business does
                </h3>
                <p>
                  Easily set up different departments – such as bar, kitchen,
                  front of house – for clocking in and reporting on costs.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoHappy />
                  <br />
                  Free support
                </h3>
                <p>
                  Our friendly Wellington-based support team is available by
                  phone or email to help you out whenever you need it.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoSearch />
                  <br />
                  Try before you buy
                </h3>
                <p>
                  With a fully featured{" "}
                  <Link to="/signup">free 14 day trial</Link>, you can take
                  PayHero for a risk-free test run before committing.
                </p>
              </Box>
            </Row>
          </Box>
          <CustomerQuote
            quote="Thanks to the photo time clock, the amount we’re saving on wages more than pays for our monthly bill. It’s a no-brainer for us."
            name="Bridget Dunn"
            company="Prefab"
            pic="Prefab_Circle.png"
            large
            center
          />
        </Wrapper>
      </Container>
      <CallToAction showSalesButton />
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default Hospitality;
